import React from 'react';

interface INoData {
	title: string;
	subtitle?: string;
}

export const NoData = (props: INoData) => {
	const { title, subtitle } = props;

	return (
		<div className="flex flex-col text-center">
			<div>{title}</div>
			{subtitle && <div>{subtitle}</div>}
		</div>
	);
};
