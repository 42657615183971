import { createModel } from '@rematch/core';
import { IAPICall } from 'rematch/interfaces';
import { RootModel } from '.';

export const request = createModel<RootModel>()({
	state: {},
	reducers: {},
	effects: (dispatch) => ({
		async anonymousRequest(payload: IAPICall, store) {
			try {
				const { url, method, body, contentType } = payload;

				let res = null;
				if (method === 'GET' || method === 'DELETE') {
					res = await fetch(url, {
						method,
					}).then((response) => response.json());
				} else {
					// const headers = {
					// 	['Content-Type']: 'application/json',
					// };
					// if (contentType) {
					// 	headers['Content-Type'] = contentType;
					// }
					res = await fetch(url, {
						method,
						// headers,
						body,
					}).then((response) => response.json());
				}

				return res;
			} catch (error) {
				console.log('anonymousRequest', payload.url, error);
				return error;
			}
		},
	}),
});
