import { getFirestore } from '@firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './active/constants';

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore();

export enum EIcon {
	TableCaptainHat = 'Customer:table-captain-bookings',
	Search = 'Customer:search-for',
	ArrowDown = 'Customer:myday-expand',
	ArrowUp = 'Customer:myday-collapse',
	LinkedIn = 'Customer:linkedin',
}

export const Alpha10Percent = '1A';
