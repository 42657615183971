import { Avatar } from 'components/Avatar';
import { Alpha10Percent } from 'configs/constants';
import { getColorFromCSSString, isEmptyString } from 'helper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import moment from 'moment';
import { CATEGORY3FILTERS, slotTimes } from 'configs/active/constants';
import { ISpeaker, ITeam } from 'configs/interfaces/data23';
import useReactRouter from 'use-react-router';

interface ISectionListEntry {
	item: ITeam;
	height: number;
	slotId: number;
	onClick: (team: ITeam) => void;
}

export const SectionListEntry = (props: ISectionListEntry) => {
	const { item, height, slotId, onClick } = props;
	const { location } = useReactRouter();

	const [remainingSeats, setRemainingSeats] = useState<number>(8);
	const [schedule, setSchedule] = useState<any>(undefined);
	const [speaker, setSpeaker] = useState<ISpeaker | undefined>(undefined);

	const teamBookings = useSelector((store: IRootState) => store.booking.teamBookings);
	const speakers = useSelector((store: IRootState) => store.data.speakers);

	useEffect(() => {
		let _schedule: typeof schedule = undefined;
		let _speaker: typeof speaker = undefined;

		if (location.state) {
			const { slotId } = location.state as any;
			_schedule = slotTimes[slotId - 1];
		}

		if (item.maxUsers) {
			let _remaining = item.maxUsers;

			const teamBooking = teamBookings.find((e) => e.slotId === slotId && e.teamId === item.id);
			if (teamBooking) {
				_remaining = Math.max(0, _remaining - teamBooking.users.length);
			}

			setRemainingSeats(_remaining);
		}

		if (item.speaker) {
			_speaker = speakers?.find((e) => !e.isDeleted && e.id === item.speaker);
		}

		setSchedule(_schedule);
		setSpeaker(_speaker);
	}, [item, speakers, teamBookings]);

	const _renderCategories = () => {
		const tags: string[] = [];

		if (item.teamCategory3) {
			item.teamCategory3.forEach((tag) => {
				const found = CATEGORY3FILTERS.find((e) => e.key === tag);
				if (found) {
					tags.push(found.label);
				}
			});
		}

		if (tags.length > 0) {
			return (
				<div className="flex flex-wrap">
					{tags.map((tag) => {
						return (
							<div key={`${item.id}_${tag}`} className="bg-background text-xs px-2 py-px mr-1 mb-1">
								{tag}
							</div>
						);
					})}
				</div>
			);
		}

		return null;
	};

	const _renderJobtitleAndCompany = () => {
		let str = '';

		if (speaker?.jobTitle && speaker.company) {
			str = `${speaker.jobTitle} | ${speaker.company}`;
		} else if (speaker?.jobTitle) {
			str = speaker.jobTitle;
		} else if (speaker?.company) {
			str = speaker.company;
		}

		if (!isEmptyString(str)) {
			return <div className="text-xs text-black text-left">{str}</div>;
		}

		return null;
	};

	const _getTitle = () => {
		let title = item.title;

		if (speaker) {
			if (speaker.title) {
				title = `${speaker.title} ${speaker.firstName} ${speaker.lastName}`;
			} else {
				title = `${speaker.firstName} ${speaker.lastName}`;
			}
		}

		return title;
	};

	return (
		<button
			className="relative w-full mb-2 flex items-center px-2 bg-warning-500"
			style={{ minHeight: height }}
			onClick={() => onClick(item)}
		>
			<Avatar containerCss="my-auto" size="xs" avatar={speaker?.image ?? item.image} fullName={item.title} />

			<div className="flex flex-col flex-1 pl-2 xl:pl-4 items-start">
				{schedule?.startDate && schedule?.endDate && (
					<div
						className="absolute top-2 right-2 text-xs self-end px-2 py-px bg-black text-warning-500"
						style={{ backgroundColor: schedule.backgroundColor, color: schedule.textColor }}
					>{`${moment(schedule.startDate, 'DD.MM.YYYY HH:mm').format('HH:mm')} - ${moment(
						schedule.endDate,
						'DD.MM.YYYY HH:mm',
					).format('HH:mm')}`}</div>
				)}
				<div className="font-bold text-sm">{_getTitle()}</div>
				{_renderJobtitleAndCompany()}
				{_renderCategories()}
			</div>
			<div className="px-1 mt-4">
				<div className={`text-center text-2xl ${remainingSeats > 0 ? 'font-bold' : 'text-lightgray'}`}>{remainingSeats}</div>
				<div className={`text-xs leading-3 text-center ${remainingSeats === 0 ? 'text-lightgray' : ''}`}>places</div>
			</div>
		</button>
	);
};
