import React from 'react';

import { Card } from 'components/Container';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { numberOfSlots } from 'configs/active/constants';

import _ from 'lodash';
import { UserBooking } from 'components/Booking';

export const MyBookingsScreen = () => {
	const decryptedToken = useSelector((store: IRootState) => store.auth.decryptedToken);

	const gridCols =
		numberOfSlots > 1
			? `grid-cols-1  gap-x-4 xl:gap-x-8 md:grid-cols-2 ${numberOfSlots > 2 ? 'xl:grid-cols-3' : ''}`
			: 'grid-cols-1 w-full md:w-3/4 xl:w-1/2 mx-auto';

	return (
		<Card hatColor="text-warning-500">
			<div className="text-warning-500 font-bold text-lg md:text-2xl text-center">{`Hi ${decryptedToken?.firstName}, ${
				numberOfSlots > 1 ? 'here are your bookings:' : 'here is your booking:'
			}`}</div>

			<div className={`grid ${gridCols} mt-8`}>
				{_.times(numberOfSlots, (idx) => (
					<UserBooking key={`userbooking_${idx + 1}`} slotId={idx + 1} />
				))}
			</div>
		</Card>
	);
};
