import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../configs/active/tailwind.config.js';

const twFullConfig = resolveConfig(tailwindConfig);

export const getColorFromCSSString = (str?: string) => {
	let color = '';
	if (str) {
		const splitted = str.split('-');
		if (twFullConfig.theme.colors[splitted[1]] && twFullConfig.theme.colors[splitted[1]][splitted[2]]) {
			color = twFullConfig.theme.colors[splitted[1]][splitted[2]];
		}
	}

	return color;
};
