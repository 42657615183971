import React, { useEffect, useState } from 'react';

import { Moment } from 'moment-timezone';
import { useDate } from 'components/hooks';
import { IDateDifference } from 'configs/interfaces';
import { Card } from 'components/Container';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { SectionInfo, SectionLabel } from 'components/Text';

import TC from '../../assets/tablecaptain.png';
import TABLE_BUBBLE from '../../assets/table_bubble.png';
interface IBookingNotOpenedYet {
	now: Moment;
	start: Moment;
}

export const BookingNotOpenedYet = (props: IBookingNotOpenedYet) => {
	const { now, start } = props;
	const { getDateDifference } = useDate();

	const [difference, setDifference] = useState<IDateDifference | undefined>(undefined);

	const decryptedToken = useSelector((store: IRootState) => store.auth.decryptedToken);

	useEffect(() => {
		const diff = getDateDifference(now, start);
		setDifference(diff);
	}, [now, start]);

	const _renderTime = (value?: number, label?: string) => {
		return (
			<div className="flex flex-col items-center text-danger-500 ">
				<div className="text-3xl font-bold leading-none">{value}</div>
				<div className="text-lg leading-none">{label}</div>
			</div>
		);
	};

	const _renderHorizontalSeperator = () => {
		return <div className="w-full my-4 h-px bg-lightgray" />;
	};

	const _renderSeperator = () => {
		return <div className="text-danger-500 text-3xl mx-1">-</div>;
	};

	return (
		<Card hatColor="text-danger-500">
			<div className="text-danger-500 font-bold text-lg md:text-2xl text-center">{`Hi ${decryptedToken?.firstName}, booking will start in:`}</div>
			{_renderHorizontalSeperator()}
			<div className="flex items-center justify-center text-danger-500">
				{_renderTime(difference?.days, 'days')}
				{_renderSeperator()}
				{_renderTime(difference?.hours, 'hours')}
				{_renderSeperator()}
				{_renderTime(difference?.minutes, 'minutes')}
				{_renderSeperator()}
				{_renderTime(difference?.seconds, 'seconds')}
			</div>
			{_renderHorizontalSeperator()}
			<div className="w-full flex items-start">
				<div className="flex-1 pr-6 text-white">
					<SectionLabel label="Starting time:" />
					<SectionInfo>
						<p className="text-sm">Booking will open Wednesday, 25 September at 16:00 (CEST)</p>
					</SectionInfo>

					<SectionLabel label="Please note:" />
					<SectionInfo>
						<p className="text-sm">
							You can find detailed information about the Table Captains and FAQs{' '}
							<a className="underline" href="https://www.bitsandpretzels.com/table-captains">
								here
							</a>
						</p>
					</SectionInfo>

					<SectionLabel label="Rules" />
					<SectionInfo>
						<ul className="list-disc list-inside pl-4 text-sm">
							<li>Every attendee can select one Table Captain per round. There are two rounds, 45-minutes each.</li>
							<li>
								Table Captains are selected on a first-come-first-served basis. Better be quick. Do your research before the
								start.
							</li>
							<li>
								Even if all tables are full, every attendee will be able to come to the tent. There will be a free seating
								area and you will have plenty of chances to network.
							</li>
							<li>Please respect the bookings of other attendees.</li>
							<li>Please don’t select a table if you are not planning to attend the last day of Bits & Pretzels.</li>
						</ul>
					</SectionInfo>

					<SectionLabel label="Oktoberfest Time Table - 01 October 2024" />
					<SectionInfo>
						<p className="text-sm">
							<div className="flex mt-4">
								10:00 - 10:45 - <div className="font-bold ml-1">Find your table</div>
							</div>
							<div>Get to the Festhalle Schottenhamel and find your table.</div>

							<div className="flex mt-4">
								10:45 - 11:30 - <div className="font-bold ml-1">Table Captain Round 1</div>
							</div>
							<div>Pitch, chat, and discuss as much as you can.</div>

							<div className="flex mt-4">
								11:30 - 11:45 - <div className="font-bold ml-1">Switch tables</div>
							</div>
							<div>Captains stay at their table and attendees switch.</div>

							<div className="flex mt-4">
								11:45 - 12:30 - <div className="font-bold ml-1">Table Captain Round 2</div>
							</div>
							<div>A new captain, a new topic, and a new round of people at your table.</div>
						</p>
					</SectionInfo>

					<SectionLabel label="What is a Table Captain:" />
					<SectionInfo>
						<p className="text-sm">
							A Table Captain is a handpicked expert from the startup ecosystem - like an investor, founder, CEO or other
							decision-makers. He or she will host the table and share their knowledge.
						</p>
					</SectionInfo>

					<SectionLabel label="What's a Topic Table" />
					<SectionInfo>
						<p className="text-sm">
							A Topic Table doesn't have a host. At this table attendees with a shared interest in the topic of the table come
							together to network.
						</p>
					</SectionInfo>

					<SectionLabel label="When & where:" />
					<SectionInfo>
						<p className="text-sm">
							Day 3 - 01 October - will take place at the biggest tent of the original Munich Oktoberfest. At 10:00 in the
							morning, all attendees will gather at the Festhalle Schottenhamel.
						</p>
					</SectionInfo>
				</div>
				<img src={TC} className="w-12 md:w-24 xl:w-32 object-contain" alt="TC" />
			</div>
			<div className="w-full md:w-3/4 xl:w-1/2 mx-auto">
				<img src={TABLE_BUBBLE} className="w-full object-contain" alt="table" />
				<a href="https://www.bitsandpretzels.com/festival/table-captains" target="_blank" rel="noopenner noreferrer">
					<div className="bg-success-500 py-2 xl:py-4 mt-6">
						<div className="text-center font-bold text-white md:text-xl xl:text-2xl">See all Table Captains</div>
					</div>
				</a>
			</div>
		</Card>
	);
};
