import { Spinner } from 'components/Spinner';
import React from 'react';

interface IButton {
	title: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	type?: 'primary' | 'secondary' | 'warning' | 'danger' | 'success';
	size?: 'sm' | 'lg';
	isLoading?: boolean;
	isDisabled?: boolean;
	isOutline?: boolean;
	buttonCss?: string;
}

export const Button = (props: IButton) => {
	const { title, onClick, type, size, isLoading, isDisabled, isOutline, buttonCss } = props;

	let _buttonCss = '';
	let _textCss = '';

	if (isDisabled) {
		_buttonCss = isOutline ? 'border-lightgray text-lightgray' : 'bg-lightgray';
	} else {
		switch (type) {
			case 'secondary':
				_buttonCss = isOutline ? 'border-secondary-500 text-secondary-500' : 'bg-secondary-500';
				break;
			case 'warning':
				_buttonCss = isOutline ? 'border-warning-500 text-warning-500' : 'bg-warning-500 text-black';
				break;
			case 'danger':
				_buttonCss = isOutline ? 'border-danger-500 text-danger-500' : 'bg-danger-500 text-black';
				break;
			case 'success':
				_buttonCss = isOutline ? 'border-success-500 text-success-500' : 'bg-success-500 text-white';
				break;
			case 'primary':
			default:
				_buttonCss = isOutline ? 'border-primary-500 text-primary-500' : 'bg-primary-500 text-white';
				break;
		}
	}

	switch (size) {
		case 'sm':
			_textCss = 'px-2 py-2 text-sm';
			break;
		case 'lg':
			_textCss = 'px-4 py-4 text-lg';
			break;
		default:
			_textCss = 'px-4 py-2 text-md';
			break;
	}

	return (
		<button
			type="button"
			className={`border flex items-center justify-center font-bold ${_buttonCss} ${_textCss} ${buttonCss}`}
			onClick={(e) => {
				if (!isDisabled && onClick) {
					onClick(e);
				}
			}}
			disabled={isLoading || isDisabled}
		>
			{isLoading ? <Spinner /> : title}
		</button>
	);
};
