import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import useReactRouter from 'use-react-router';
import { IKVP } from 'configs/interfaces';
import { Filterbar, FilterCloud, Searchbar } from 'components/FilterAndSearch';
import { CATEGORY2FILTERS, CATEGORY3FILTERS, maximumNumberOfTableCaptains, numberOfSlots, teamCategory4 } from 'configs/active/constants';
import { NoData } from 'components/NoData';
import { Card } from 'components/Container';
import { SectionList } from 'components/List';
import { Button } from 'components/Button';
import { Redirect } from 'react-router-dom';
import { ITeam } from 'configs/interfaces/data23';

export interface ISection {
	title: string;
	items: ITeam[];
	key?: string;
	order?: number;
}

export const OverviewScreen = () => {
	const { history, location } = useReactRouter();

	const [teamsForSlot, setTeamsForSlot] = useState<ITeam[]>([]);
	const [groupedTeams, setGroupedTeams] = useState<ITeam[]>([]);
	const [filteredTeams, setFilteredTeams] = useState<ITeam[]>([]);
	const [searchedTeams, setSearchedTeams] = useState<ITeam[]>([]);
	const [sections, setSections] = useState<ISection[]>([]);
	const [activeGroup, setActiveGroup] = useState<string | undefined>(undefined);

	const [hasReachedTCLimit, setHasReachedTCLimit] = useState<boolean>(false);

	const [searchTerm, setSearchTerm] = useState<string>('');
	const [activeFilters, setActiveFilters] = useState<string[]>(['All']);
	const [filters, setFilters] = useState<IKVP[]>([]);

	const teams = useSelector((store: IRootState) => store.data.teams);
	const speakers = useSelector((store: IRootState) => store.data.speakers);
	const myBookings = useSelector((store: IRootState) => store.booking.myBookings);
	const decryptedToken = useSelector((store: IRootState) => store.auth.decryptedToken);

	useEffect(() => {
		if (myBookings && location.state) {
			const { slotId } = location.state as any;

			const bookingForSlot = myBookings.slots.find((e) => e.slotId === slotId);
			if (bookingForSlot) {
				history.push('/');
			} else {
				const otherBookings = myBookings.slots.filter((e) => e.slotId !== slotId);

				const myTCBookings: ITeam[] = [];

				otherBookings.forEach((booking) => {
					const found = teams.find((e) => e.id === booking.teamId);
					if (found?.teamCategory2?.includes('tablecaptain')) {
						myTCBookings.push(found);
					}
				});

				if (myTCBookings.length >= maximumNumberOfTableCaptains) {
					setHasReachedTCLimit(true);
					setActiveGroup('topictable');
				} else {
					setHasReachedTCLimit(false);
				}
			}
		}
	}, [myBookings, teams, history, location.state]);

	useEffect(() => {
		setActiveFilters(['All']);
		if (activeGroup === 'tablecaptain') {
			setFilters(CATEGORY3FILTERS);
		} else if (activeGroup === 'topictable') {
			setFilters(
				[{ key: 'Free Seating Area', label: 'Free Seating Area' }].concat(
					teamCategory4.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1)),
				),
			);
		}
	}, [activeGroup]);

	useEffect(() => {
		let _teamsForSlot = teams ? [...teams] : [];

		if (location.state) {
			const { slotId } = location.state as any;

			_teamsForSlot = _teamsForSlot.filter((team) => {
				const teamCategory1 = team.teamCategory1 ? team.teamCategory1.split(',') : [];
				return team.maxUsers && teamCategory1.includes(slotId.toString());
			});
		}

		setTeamsForSlot(_teamsForSlot);
	}, [teams, location.state]);

	useEffect(() => {
		let _grouped = [...teamsForSlot];

		if (activeGroup) {
			_grouped = _grouped.filter((team) => {
				const teamCategory2 = team.teamCategory2 ? team.teamCategory2.split(',') : [];
				return teamCategory2.includes(activeGroup);
			});
		}

		setGroupedTeams(_grouped);
	}, [activeGroup, teamsForSlot]);

	useEffect(() => {
		let _filteredTeams = [...groupedTeams];

		if (!activeFilters.includes('All')) {
			_filteredTeams = _filteredTeams.filter((team) => {
				let cat = team.teamCategory3 ? team.teamCategory3 : [];

				if (activeGroup === 'topictable') {
					cat = team.teamCategory4 ? team.teamCategory4 : [];
				}

				for (const activeFilter of activeFilters) {
					if (cat.includes(activeFilter)) {
						return true;
					}
				}

				return false;
			});
		}

		setFilteredTeams(_filteredTeams);
	}, [groupedTeams, activeFilters, activeGroup]);

	useEffect(() => {
		let searched = [...filteredTeams];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			searched = searched.filter((e) => {
				if (e.title?.toLowerCase().includes(lowerSearch)) {
					return true;
				}

				if (e.speaker) {
					const _speaker = speakers?.find((sp) => !sp.isDeleted && sp.id === e.speaker);
					if (
						_speaker?.title?.toLowerCase().includes(lowerSearch) ||
						_speaker?.jobTitle?.toLowerCase().includes(lowerSearch) ||
						_speaker?.company?.toLowerCase().includes(lowerSearch) ||
						_speaker?.bio?.toLowerCase().includes(lowerSearch)
					) {
						return true;
					}
				}

				return false;
			});
		}

		setSearchedTeams(searched);
	}, [filteredTeams, speakers, searchTerm]);

	useEffect(() => {
		let _sections: typeof sections = [];

		if (activeGroup === 'tablecaptain') {
			searchedTeams.forEach((team) => {
				const splitted = team.title.split(' ');
				const char = splitted[splitted.length - 1].charAt(0).toUpperCase();
				const found = _sections.find((e) => e.title === char);
				if (found) {
					found.items.push(team);
				} else {
					_sections.push({
						title: char,
						items: [team],
					});
				}
			});

			_sections.sort((a, b) => (a.title < b.title ? -1 : 1));
			_sections.forEach((section) => {
				section.items.sort((a, b) => {
					const aSplit = a.title.toLowerCase().split(' ');
					const bSplit = b.title.toLowerCase().split(' ');

					return aSplit[aSplit.length - 1] < bSplit[bSplit.length - 1] ? -1 : 1;
				});
			});
		} else {
			_sections.push({
				key: 'Free Seating Area',
				title: 'Free Seating Area',
				items: [],
			});
			teamCategory4.forEach((category) => {
				_sections.push({
					key: category.key,
					title: category.label,
					items: [],
				});
			});
			searchedTeams.forEach((team) => {
				if (team.teamCategory4) {
					team.teamCategory4.forEach((key) => {
						const found = _sections.find((e) => e.key === key);
						if (found) {
							found.items.push(team);
						}
					});
				}
			});

			_sections = _sections.filter((e) => e.items.length > 0);

			_sections.sort((a, b) => {
				const aVal = a.order ? a.order : 999;
				const bVal = b.order ? b.order : 999;

				return aVal < bVal ? -1 : 1;
			});

			_sections.forEach((section) => {
				section.items.sort((a, b) => {
					const aVal = a.order ? a.order : 999;
					const bVal = b.order ? b.order : 999;

					return aVal < bVal ? -1 : 1;
				});
			});
		}

		setSections(_sections);
	}, [searchedTeams, activeGroup]);

	const _handleClick = (team: ITeam) => {
		const { slotId } = location.state as any;

		history.push({
			pathname: 'details',
			state: {
				slotId,
				teamId: team.id,
			},
		});
	};

	const _renderTeams = () => {
		const { slotId } = location.state as any;

		if (searchedTeams.length > 0) {
			return (
				<SectionList
					sections={sections}
					slotId={slotId}
					onClick={(team) => _handleClick(team)}
					hideJumpButtons={activeGroup === 'topictable'}
				/>
			);
		} else if (searchTerm.length >= 3 || !activeFilters.includes('All')) {
			return <NoData title="No tables matching your search were found" />;
		}

		return null;
	};

	if (location.state) {
		const { slotId } = location.state as any;

		if (slotId) {
			return (
				<Card hatColor="text-success-500">
					<div className="text-warning-500 font-bold text-lg md:text-2xl text-center mb-8">{`${decryptedToken?.firstName}, it's time to select your table:`}</div>

					<div className="flex-1 flex flex-col md:flex-row">
						<div className="flex flex-col w-full md:w-1/2 md:px-2 xl:px-4">
							<div className="flex mb-6">
								<Button
									title={numberOfSlots > 1 ? '< my bookings' : '< my booking'}
									type="warning"
									onClick={() => history.push('/')}
								/>
							</div>
							<Searchbar
								value={searchTerm}
								onChange={(value) => setSearchTerm(value)}
								placeholder="Search by name or company"
							/>
							<Filterbar
								value={activeGroup}
								onChange={(val) => setActiveGroup(val)}
								options={CATEGORY2FILTERS}
								hasReachedTCLimit={hasReachedTCLimit}
							/>
							<FilterCloud value={activeFilters} onChange={(val) => setActiveFilters(val)} options={filters} />
						</div>
						<div className="flex flex-col w-full md:w-1/2 md:px-2 xl:px-4">{_renderTeams()}</div>
					</div>
				</Card>
			);
		}
	}

	return <Redirect to="/" />;
};
