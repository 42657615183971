import { ITeam } from 'configs/interfaces/data23';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

export const Footer = () => {
	const [team, setTeam] = useState<ITeam | undefined>(undefined);

	const decryptedToken = useSelector((store: IRootState) => store.auth.decryptedToken);
	const teams = useSelector((store: IRootState) => store.data.teams);

	const logout = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.logout);

	useEffect(() => {
		let _team: typeof team = undefined;

		if (teams && decryptedToken?.id) {
			_team = teams.find((e) => e.id === decryptedToken.id);
		}

		setTeam(_team);
	}, [teams, decryptedToken]);

	const _renderTitle = () => {
		if (decryptedToken) {
			let str = `Not ${decryptedToken.firstName}?`;
			if (team) {
				str = `Not ${team.title} ?`;
			}

			return <div className="font-bold text-white text-sm lg:text-lg">{str}</div>;
		}

		return null;
	};

	if (decryptedToken) {
		return (
			<nav className="relative bg-black h-8 lg:h-12 flex items-center px-2">
				{_renderTitle()}
				<div className="ml-1 cursor-pointer text-white text-sm lg:text-lg" onClick={() => logout({})}>
					Click here
				</div>
			</nav>
		);
	}

	return null;
};
