import React, { useEffect } from 'react';
import { Button } from 'components/Button';
import { IKVP } from 'configs/interfaces';

interface IFilterbar {
	value?: string;
	onChange: (value: string) => void;
	options: IKVP[];
	hasReachedTCLimit?: boolean;
}

export const Filterbar = (props: IFilterbar) => {
	const { value, onChange, options, hasReachedTCLimit } = props;

	useEffect(() => {
		if (options.length > 0 && !value) {
			onChange(options[0].key);
		}
	}, []);

	if (options.length > 1) {
		return (
			<div className="grid grid-cols-2 space-x-6 mb-4">
				{options.map((option) => {
					return (
						<Button
							key={`filterbaroption_${option.key}`}
							type="warning"
							title={option.label}
							isDisabled={option.key === 'tablecaptain' && hasReachedTCLimit}
							onClick={() => onChange(option.key)}
							isOutline={value !== option.key}
						/>
					);
				})}
			</div>
		);
	}

	return null;
};
