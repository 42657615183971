import React from 'react';

import NavbarLogo from 'configs/active/navbar.png';

export const Navbar = () => {
	return (
		<nav className="relative bg-black h-12 flex items-center px-2">
			<img src={NavbarLogo} className="h-full w-auto" alt="" />
		</nav>
	);
};
