import { IKVP } from 'configs/interfaces';

export const REGION = 'europe-west3';

export const EVENTTIMEZONE = 'Europe/Berlin';

export const firebaseConfig = {
	apiKey: 'AIzaSyBix00bDuI_0UasyecnPT271dUMY-Ple3g',
	authDomain: 'bits22.firebaseapp.com',
	projectId: 'bits22',
	storageBucket: 'bits22.appspot.com',
	messagingSenderId: '610929323812',
	appId: '1:610929323812:web:22476d1c48904face94db1',
};

export const RESOLVETOKENURL = 'https://europe-west3-bits22.cloudfunctions.net/resolveUserToken';
export const BOOKSLOTURL = 'https://europe-west3-bits22.cloudfunctions.net/bookSlot';
export const CHECKTICKETCODEURL = 'https://europe-west3-bits22.cloudfunctions.net/checkTicketCode';
export const CANCELSLOTURL = 'https://europe-west3-bits22.cloudfunctions.net/cancelSlot';

export const numberOfSlots = 2;
export const maximumNumberOfTableCaptains = 2;
export const EVENTCODE = 'bits24';
export const APPLICATIONURL = 'https://captain.bitsandpretzels.com';
export const EVENTTITLE = 'Bits & Pretzels - Table Captain Booking';
export const FAVICONURL = 'https://hspxde.blob.core.windows.net/bits22/assets/bitslogo_c765a88e2b.svg';

export const CATEGORY2FILTERS: IKVP[] = [
	{
		key: 'tablecaptain',
		label: 'Table Captains',
	},
	{
		key: 'topictable',
		label: 'Topic Tables',
	},
];

export const CATEGORY3FILTERS: IKVP[] = [
	// {
	// 	key: 'All',
	// 	label: 'All',
	// },
	{
		key: 'Founder',
		label: 'Founders',
	},
	{
		key: 'Investor',
		label: 'Investors',
	},
	{
		key: 'Corporate',
		label: 'Corporates',
	},
	{
		key: 'Media',
		label: 'Media',
	},
];

export const teamCategory4: IKVP[] = [
	{
		key: 'IoT & Data',
		label: 'IoT & Data',
	},
	{
		key: 'AI',
		label: 'AI ',
	},
	{
		key: 'Business Structures & Strategies',
		label: 'Business Structures & Strategies ',
	},
	{
		key: 'ClimateTech',
		label: 'ClimateTech',
	},
	{
		key: 'Marketplaces',
		label: 'Marketplaces',
	},
	{
		key: 'Crypto',
		label: 'Crypto',
	},
	{
		key: 'Entertainment',
		label: 'Entertainment',
	},
	{
		key: 'Future of Work',
		label: 'Future of Work',
	},
	{
		key: 'Growth',
		label: 'Growth',
	},
	{
		key: 'HealthTech',
		label: 'HealthTech',
	},
	{
		key: 'Finances',
		label: 'Finances',
	},
	{
		key: 'Leadership',
		label: 'Leadership',
	},
	{
		key: 'Marketing',
		label: 'Marketing',
	},
	{
		key: 'Mobility',
		label: 'Mobility',
	},
	{
		key: 'SaaS',
		label: 'SaaS',
	},
	{
		key: 'Social Development & Impact',
		label: 'Social Development & Impact',
	},
];

export const slotTimes = [
	{
		startDate: '01.10.2024 10:45',
		endDate: '01.10.2024 11:30',
	},
	{
		startDate: '01.10.2024 11:45',
		endDate: '01.10.2024 12:30',
	},
];
