import React from 'react';

import { Card } from 'components/Container';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

interface IBookingClosed {}

export const BookingClosed = (props: IBookingClosed) => {
	const decryptedToken = useSelector((store: IRootState) => store.auth.decryptedToken);

	return (
		<Card hatColor="text-danger-500">
			<div className="text-danger-500 font-bold text-lg md:text-2xl text-center">{`Hi ${decryptedToken?.firstName}, booking has ended`}</div>
		</Card>
	);
};
