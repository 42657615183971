import { Avatar } from 'components/Avatar';
import { Button } from 'components/Button';
import { CATEGORY3FILTERS, slotTimes } from 'configs/active/constants';
import { Alpha10Percent, EIcon } from 'configs/constants';
import { ITeamBooking, ITeamBookingUser } from 'configs/interfaces';
import { getColorFromCSSString, isEmptyString } from 'helper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import useReactRouter from 'use-react-router';
import moment from 'moment';
import { Icon } from 'components/Icon';
import { ISpeaker, ITeam } from 'configs/interfaces/data23';

interface IUserBookingComponent {
	slotId: number;
}

export const UserBooking = (props: IUserBookingComponent) => {
	const { slotId } = props;
	const { history } = useReactRouter();

	const [speaker, setSpeaker] = useState<ISpeaker | undefined>(undefined);
	const [team, setTeam] = useState<ITeam | undefined>(undefined);
	const [teamBooking, setTeamBooking] = useState<ITeamBooking | undefined>(undefined);
	const [isCancelSlotLoading, setIsCancelSlotLoading] = useState<boolean>(false);
	const [schedule, setSchedule] = useState<any>(undefined);

	const myBookings = useSelector((store: IRootState) => store.booking.myBookings);
	const teamBookings = useSelector((store: IRootState) => store.booking.teamBookings);
	const teams = useSelector((store: IRootState) => store.data.teams);
	const speakers = useSelector((store: IRootState) => store.data.speakers);

	const cancelSlot = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.cancelSlot);

	useEffect(() => {
		let _team: typeof team = undefined;
		let _speaker: typeof speaker = undefined;
		let _schedule: typeof schedule = slotTimes[slotId - 1];

		if (myBookings?.slots) {
			const bookingSlot = myBookings.slots.find((e) => e.slotId === slotId);
			if (bookingSlot) {
				_team = teams?.find((e) => e.id === bookingSlot.teamId);

				if (_team?.speaker) {
					_speaker = speakers?.find((e) => !e.isDeleted && e.id === _team?.speaker);
				}
			}
		}

		setTeam(_team);
		setSpeaker(_speaker);
		setSchedule(_schedule);
	}, [myBookings, teams, speakers, slotId]);

	useEffect(() => {
		let _teamBooking: typeof teamBooking = undefined;

		if (team) {
			_teamBooking = teamBookings.find((e) => e.slotId === slotId && e.teamId === team.id);
		}

		setTeamBooking(_teamBooking);
	}, [team, teamBookings]);

	const _cancelSlot = async () => {
		if (team) {
			setIsCancelSlotLoading(true);
			await cancelSlot({ slotId });
			setIsCancelSlotLoading(false);
		}
	};

	const _handleButtonClick = () => {
		if (team) {
			_cancelSlot();
		} else {
			history.push({
				pathname: 'overview',
				state: {
					slotId,
				},
			});
		}
	};

	const _renderImage = () => {
		if (team) {
			return (
				<>
					<Avatar size="md" avatar={speaker?.image ?? team.image} fullName={team.title} containerCss="flex md:hidden" inverse />
					<Avatar
						size="xl"
						avatar={speaker?.image ?? team.image}
						fullName={team.title}
						containerCss="hidden md:flex xl:hidden"
						inverse
					/>
					<Avatar size="xxl" avatar={speaker?.image ?? team.image} fullName={team.title} containerCss="hidden xl:flex" inverse />
				</>
			);
		}

		return <div className="w-20 xl:w-40" />;
	};

	const _renderJobtitleAndCompany = () => {
		let str = '';

		if (speaker?.jobTitle && speaker?.company) {
			str = `${speaker.jobTitle} | ${speaker.company}`;
		} else if (speaker?.jobTitle) {
			str = speaker.jobTitle;
		} else if (speaker?.company) {
			str = speaker.company;
		}

		if (!isEmptyString(str)) {
			return <div className="xl:text-xl mt-2">{str}</div>;
		}

		return null;
	};

	const _renderCategories = () => {
		const tags: string[] = [];

		if (team?.teamCategory3) {
			team?.teamCategory3.forEach((tag) => {
				const found = CATEGORY3FILTERS.find((e) => e.key === tag);
				if (found) {
					tags.push(found.label);
				}
			});
		}

		if (tags.length > 0) {
			return (
				<div className="flex flex-col mt-2">
					{tags.map((tag) => (
						<div className="bg-background px-2 py-1 mb-1 text-center">{tag}</div>
					))}
				</div>
			);
		}

		return null;
	};

	const _renderUser = (user: ITeamBookingUser) => {
		let fullName = `${user.firstName}`;

		return (
			<>
				<Avatar fullName={fullName} showNameOnHover user={user} size="xxs" containerCss="flex md:hidden" inverse />
				<Avatar fullName={fullName} showNameOnHover user={user} size="xs" containerCss="hidden md:flex xl:hidden" inverse />
				<Avatar fullName={fullName} showNameOnHover user={user} size="sm" containerCss="hidden xl:flex" inverse />
			</>
		);
	};

	const _getTitle = () => {
		let title = team?.title;

		if (speaker) {
			if (speaker.title) {
				title = `${speaker.title} ${speaker.firstName}`;
			} else {
				title = `${speaker.firstName}`;
			}
		}

		return title;
	};

	const _renderContent = () => {
		if (team) {
			return (
				<div className="w-full flex flex-col px-4 py-4">
					<div className="flex items-center">
						{_renderImage()}
						<div className="pl-4 flex flex-col text-left xl:mx-auto">
							<div className="flex items-center">
								<div className="md:text-xl xl:text-2xl font-bold border-b border-lightgray w-max">{_getTitle()}</div>
								{speaker?.linkedInProfileUrl && (
									<button className="ml-2" onClick={() => window.open(speaker.linkedInProfileUrl, '_blank')}>
										<Icon name={EIcon.LinkedIn} className="text-primary-500 md:text-xl xl:text-2xl" />
									</button>
								)}
							</div>
							{_renderJobtitleAndCompany()}
							{_renderCategories()}
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="flex-1 flex justify-center items-center" style={{ minHeight: 100 }}>
				<div className="text-black font-bold text-xl xl:text-3xl text-center">You have not selected a table yet</div>
			</div>
		);
	};

	return (
		<div className="flex-1 flex flex-col mb-6">
			<div className="font-bold text-warning-500">{`SLOT ${slotId}:`}</div>
			{team && (
				<div className="flex justify-between">
					<div className="flex flex-col">
						<div className="text-warning-500 font-bold border-b border-lightgray">
							{moment(schedule?.startDate, 'DD.MM.YYYY HH:mm').format('MMMM DD')}
						</div>
						<div className="bg-warning-500 text-black px-2 py-1 mt-2">{`${moment(
							schedule?.startDate,
							'DD.MM.YYYY HH:mm',
						).format('HH:mm')} - ${moment(schedule?.endDate, 'DD.MM.YYYY HH:mm').format('HH:mm')}`}</div>
					</div>
					<div className="flex">
						<div className="flex flex-col">
							<div className="text-warning-500 font-bold border-b border-lightgray">ROW</div>
							<div className="bg-warning-500 text-black px-2 py-1 mt-2 text-center">{team.row ?? 'TBD'}</div>
						</div>
						<div className="flex flex-col ml-2">
							<div className="text-warning-500 font-bold border-b border-lightgray">TABLE</div>
							<div className="bg-warning-500 text-black px-2 py-1 mt-2 text-center">{team.table ?? 'TBD'}</div>
						</div>
					</div>
				</div>
			)}
			{team && (
				<div className="flex flex-col w-max mt-4">
					<div className="text-warning-500 font-bold border-b border-lightgray">LOCATION</div>
					<div className="bg-background px-2 py-1 mt-2">Oktoberfest</div>
				</div>
			)}
			<button
				className="flex-1 flex flex-col mt-4 bg-box"
				style={{}}
				disabled={!team}
				onClick={() => {
					history.push({
						pathname: 'details',
						state: {
							slotId,
							teamId: team?.id,
						},
					});
				}}
			>
				<div className="flex-1 flex w-full">{_renderContent()}</div>
				{team && <div className="underline text-xs px-2">Click here for more details</div>}
				<div className="flex items-center justify-between">
					<div className="flex flex-wrap items-center py-2 px-2">{teamBooking?.users.map((user) => _renderUser(user))}</div>
				</div>
			</button>
			<div className="mt-8">
				<Button
					buttonCss="w-10/12 mx-auto "
					onClick={_handleButtonClick}
					type={team ? 'danger' : 'warning'}
					title={team ? 'LEAVE TABLE' : 'JOIN TABLE'}
					size="lg"
					isLoading={isCancelSlotLoading}
				/>
			</div>
		</div>
	);
};
