import React, { ReactNode } from 'react';

interface ISectionInfo {
	children?: ReactNode | ReactNode[];
}

export const SectionInfo = (props: ISectionInfo) => {
	const { children } = props;

	if (children) {
		return <div className="mb-4">{children}</div>;
	}

	return null;
};
