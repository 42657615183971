import { collection, Unsubscribe } from '@firebase/firestore';
import { createModel } from '@rematch/core';
import { onSnapshot, doc } from 'firebase/firestore';
import { db } from 'configs/constants';

import type { RootModel } from '.';
import { BOOKSLOTURL, CANCELSLOTURL, EVENTCODE } from 'configs/active/constants';
import { IUserBooking, ITeamBooking } from 'configs/interfaces';
import { IBookSlotParams, ICancelSlotParams } from 'rematch/interfaces';

type IDefaultState = {
	myBookings?: IUserBooking;
	myBookingsSync?: Unsubscribe;
	teamBookings: ITeamBooking[];
	teamBookingsSync?: Unsubscribe;
};

export const booking = createModel<RootModel>()({
	state: {
		myBookings: undefined,
		myBookingsSync: undefined,
		teamBookings: [],
		teamBookingsSync: undefined,
	} as IDefaultState,
	reducers: {
		setMyBookings(state, myBookings: IDefaultState['myBookings']) {
			return {
				...state,
				myBookings,
			};
		},
		setMyBookingsSync(state, myBookingsSync: IDefaultState['myBookingsSync']) {
			return {
				...state,
				myBookingsSync,
			};
		},
		addTeamBookings(state, bookings: IDefaultState['teamBookings']) {
			const _bookings = state.teamBookings ? [...state.teamBookings] : [];

			bookings.forEach((booking) => {
				const idx = _bookings.findIndex((e) => e.teamId === booking.teamId);
				if (idx !== -1) {
					_bookings[idx] = booking;
				} else {
					_bookings.push(booking);
				}
			});

			return {
				...state,
				teamBookings: _bookings,
			};
		},
		setTeamBookingsSync(state, teamBookingsSync: IDefaultState['teamBookingsSync']) {
			return {
				...state,
				teamBookingsSync,
			};
		},
		clear(state) {
			return {
				...state,
				myBookings: undefined,
				teamBookings: [],
			};
		},
	},
	effects: (dispatch) => ({
		startMyBookingsSync(payload, store) {
			try {
				if (store.auth.decryptedToken) {
					const sync = onSnapshot(
						doc(db, `${EVENTCODE}/bookings/userBookings/${store.auth.decryptedToken.ticketcode}`),
						(doc) => {
							const data = doc.data() as IDefaultState['myBookings'];
							dispatch.booking.setMyBookings(data);
						},
					);

					dispatch.booking.setMyBookingsSync(sync);
				}
			} catch (error) {
				console.log('startMyBookingsSync', error);
			}
		},
		stopMyBookingsSync(payload, store) {
			try {
				if (store.booking.myBookingsSync) {
					store.booking.myBookingsSync();
					dispatch.booking.setMyBookingsSync(undefined);
				}
			} catch (error) {
				console.log('stopMyBookingsSync', error);
			}
		},
		startTeamBookingsSync(payload, store) {
			try {
				if (store.auth.token) {
					const sync = onSnapshot(collection(db, `${EVENTCODE}/bookings/teamBookings`), (snapshot) => {
						const docs: IDefaultState['teamBookings'] = [];
						snapshot.docs.forEach((doc) => {
							const data = doc.data() as ITeamBooking;
							docs.push(data);
						});

						dispatch.booking.addTeamBookings(docs);
					});

					dispatch.booking.setTeamBookingsSync(sync);
				}
			} catch (error) {
				console.log('startTeamBookingsSync', error);
			}
		},
		stopTeamBookingsSync(payload, store) {
			try {
				if (store.booking.teamBookingsSync) {
					store.booking.teamBookingsSync();
					dispatch.booking.setTeamBookingsSync(undefined);
				}
			} catch (error) {
				console.log('stopTeamBookingsSync', error);
			}
		},
		async bookSlot(payload: IBookSlotParams, store) {
			try {
				if (store.auth.token) {
					const { slotId, teamId } = payload;

					const res: any = await dispatch.request.anonymousRequest({
						url: BOOKSLOTURL,
						method: 'POST',
						body: JSON.stringify({
							slotId,
							teamId,
							token: store.auth.token,
						}),
					});
					if (res?.userBooking) {
						dispatch.booking.setMyBookings(res.userBooking);
						return;
					}
					alert(res.error);
				}
			} catch (error) {
				console.log('bookSlot', error);
			}
		},
		async cancelSlot(payload: ICancelSlotParams, store) {
			try {
				if (store.auth.token) {
					const { slotId } = payload;

					const res: any = await dispatch.request.anonymousRequest({
						url: CANCELSLOTURL,
						method: 'POST',
						body: JSON.stringify({
							slotId,
							token: store.auth.token,
						}),
					});
					if (res?.userBooking) {
						dispatch.booking.setMyBookings(res.userBooking);
						return;
					}
					alert(res.error);
				}
			} catch (error) {
				console.log('cancelSlot', error);
			}
		},
	}),
});
