import React from 'react';

interface ISectionListHeader {
	title: string;
	height: number;
}

export const SectionListHeader = (props: ISectionListHeader) => {
	const { title, height } = props;

	return (
		<div className="w-full border-b font-bold border-warning-500" style={{ height }}>
			<div className="text-warning-500">{title}</div>
		</div>
	);
};
