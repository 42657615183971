import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import externalLinks from 'remark-external-links';

interface IRichtextRenderer {
	richtext?: string;
	containerCss?: string;
	style?: any;
}

export const RichtextRenderer = (props: IRichtextRenderer) => {
	const { richtext, containerCss, style } = props;

	let css = '';
	css = `${containerCss ? containerCss : ''}`;

	if (richtext) {
		try {
			return (
				<div className={`${style?.color ? '' : 'hs-p'} text-base ${css} text-white`} style={style}>
					<ReactMarkdown children={richtext} allowDangerousHtml className="richtext-style" />
				</div>
			);
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	return null;
};
