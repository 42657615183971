import { Avatar } from 'components/Avatar';
import { slotTimes } from 'configs/active/constants';
import { ITeamBooking } from 'configs/interfaces';
import { ISpeaker, ITeam } from 'configs/interfaces/data23';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

interface ITableCaptainBooking {
	item: ITeamBooking;
}

export const TableCaptainBooking = (props: ITableCaptainBooking) => {
	const { item } = props;

	const [team, setTeam] = useState<ITeam | undefined>(undefined);
	const [speaker, setSpeaker] = useState<ISpeaker | undefined>(undefined);

	const teams = useSelector((store: IRootState) => store.data.teams);
	const speakers = useSelector((store: IRootState) => store.data.speakers);

	useEffect(() => {
		let _team: typeof team = undefined;
		let _speaker: typeof speaker = undefined;

		if (teams) {
			_team = teams.find((e) => e.id === item.teamId);

			if (_team?.speaker) {
				_speaker = speakers.find((e) => !e.isDeleted && e.id === team?.speaker);
			}
		}

		setTeam(_team);

		setSpeaker(_speaker);
	}, [teams, speakers, item, team]);

	const _renderSlotTime = () => {
		if (slotTimes[item.slotId - 1]) {
			const { startDate, endDate } = slotTimes[item.slotId - 1];
			return (
				<div className="text-danger-500">{`${moment(startDate, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm')} - ${moment(
					endDate,
					'DD.MM.YYYY HH:mm',
				).format('HH:mm')}`}</div>
			);
		}

		return <div style={{ height: '1.5rem' }} />;
	};

	const _renderImage = () => {
		if (team) {
			if (speaker?.image) {
				return <Avatar size="xxl" avatar={speaker?.image} fullName={team.title} />;
			}

			if (team.image) {
				return <img src={team.image} className="w-20 xl:w-40 object-contain" alt="" />;
			}
		}

		return <div className="w-20 xl:w-40" />;
	};

	const _renderJobtitleAndCompany = (css: string, jobTitle?: string, company?: string) => {
		if (jobTitle || company) {
			let str = '';

			if (jobTitle && company) {
				str = `${jobTitle} | ${company}`;
			} else if (jobTitle) {
				str = jobTitle;
			} else if (company) {
				str = company;
			}

			return <div className={css}>{str}</div>;
		}

		return null;
	};

	const _renderLocation = () => {
		if (team?.row || team?.table) {
			let str = team.row;
			if (team.table) {
				str = `${str} - ${team.table}`;
			}
			return <div className="text-warning-500 font-semibold mt-4">{str}</div>;
		}

		return null;
	};

	const _renderUsers = () => {
		if (item.users.length > 0) {
			return item.users.map((user) => (
				<div key={`tablecaptain_${item.teamId}_users_${user.ticketcode}`} className="w-full flex items-center mb-2">
					<Avatar size="xs" fullName={`${user.firstName}`} inverse />
					<div className="flex flex-col flex-1 pl-2 text-warning-500">
						<div className="text-sm font-bold">{`${user.firstName}`}</div>
						{_renderJobtitleAndCompany('text-sm', user.jobTitle, user.company)}
					</div>
				</div>
			));
		}

		return <div className="flex-1 flex items-center justify-center font-bold text-primary-500">No bookings found yet</div>;
	};

	const _renderContent = () => {
		return (
			<div className="w-full flex flex-col" style={{ minHeight: 250 }}>
				<div className="w-full flex items-center mb-4">
					{_renderImage()}
					<div className="ml-4 flex flex-col text-left xl:mx-auto">
						<div className="text-warning-500 text-lg xl:text-2xl font-bold">{team?.title}</div>
						{_renderJobtitleAndCompany('xl:text-xl text-warning-500', speaker?.jobTitle, speaker?.company)}
						{_renderLocation()}
					</div>
				</div>
				<div className="font-bold text-warning-500 mb-2">Users</div>
				{_renderUsers()}
			</div>
		);
	};

	return (
		<div className="flex-1 flex p-8 mb-8">
			<div className="flex-1 flex flex-col border border-warning-500">
				<div className="bg-warning-500 py-4 flex flex-col items-center justify-center">
					<div className="text-danger-500 font-bold text-2xl">{`Slot ${item.slotId}`}</div>
					{_renderSlotTime()}
				</div>
				<div className="flex-1 flex p-4">{_renderContent()}</div>
			</div>
		</div>
	);
};
