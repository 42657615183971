module.exports = {
	purge: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
	darkMode: false, // or 'media' or 'class'
	theme: {
		colors: {
			background: '#fffae6',
			black: '#000000',
			white: '#ffffff',
			lightgray: '#aaaaaa',
			gray: '#666666',
			darkgray: '#222222',
			box: '#FFFAE6',
			primary: {
				100: '#C9FBF4',
				200: '#94F7F1',
				300: '#5DE6E8',
				400: '#34C3D2',
				500: '#0096b4',
				600: '#00759A',
				700: '#005881',
				800: '#003F68',
				900: '#002D56',
			},
			secondary: {
				100: '#CFF9CD',
				200: '#9DF3A0',
				300: '#67DD77',
				400: '#3EBB5B',
				500: '#108e3a',
				600: '#0B7A3B',
				700: '#08663A',
				800: '#055235',
				900: '#034432',
			},
			success: {
				100: '#CFF9CD',
				200: '#9DF3A0',
				300: '#67DD77',
				400: '#3EBB5B',
				500: '#108E3A',
				600: '#0B7A3B',
				700: '#08663A',
				800: '#055235',
				900: '#034432',
			},
			warning: {
				100: '#FFFBCE',
				200: '#FFF59D',
				300: '#FFEF6D',
				400: '#FFE848',
				500: '#FFDE14',
				600: '#DBBB08',
				700: '#B79A06',
				800: '#937A03',
				900: '#7A6302',
			},
			danger: {
				100: '#FDD8CD',
				200: '#FBAA9D',
				300: '#F3716A',
				400: '#E7454B',
				500: '#D80E29',
				600: '#B90A31',
				700: '#9B0736',
				800: '#7D0436',
				900: '#670235',
			},
		},
		extend: {},
	},
	variants: {
		extend: {},
	},
	plugins: [],
};
