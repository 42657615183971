import React from 'react';
import { isEmptyString } from 'helper';

interface ISectionLabel {
	label?: string;
}

export const SectionLabel = (props: ISectionLabel) => {
	const { label } = props;

	if (!isEmptyString(label)) {
		return <div className="font-bold">{label}</div>;
	}

	return null;
};
