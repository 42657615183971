import React from 'react';
import { ISection } from 'screens';
import { SectionListEntry } from './SectionListEntry';
import { SectionListHeader } from './SectionListHeader';
import { ITeam } from 'configs/interfaces/data23';

interface ISectionList {
	sections: ISection[];
	slotId: number;
	onClick: (team: ITeam) => void;
	hideJumpButtons?: boolean;
}

const SECTIONLISTHEADERHEIGHT = 25;
const SECTIONLISTENTRYHEIGHT = 100;

export const SectionList = (props: ISectionList) => {
	const { sections, slotId, onClick, hideJumpButtons } = props;

	const _handleClick = (idx: number) => {
		const el = document.getElementById(`section_${idx}`);
		if (el) {
			el.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<div className="flex flex-1 mb-4">
			<div className="flex flex-col flex-1 pr-2 overflow-y-auto" style={{ maxHeight: 500 }}>
				{sections.map((section, idx) => {
					return (
						<div key={`section_${idx}`} id={`section_${idx}`} className="flex flex-col flex-1">
							<SectionListHeader title={section.title} height={SECTIONLISTHEADERHEIGHT} />
							{section.items.map((item, itemIdx) => (
								<SectionListEntry
									key={`section_${idx}_item_${itemIdx}`}
									item={item}
									height={SECTIONLISTENTRYHEIGHT}
									slotId={slotId}
									onClick={onClick}
								/>
							))}
						</div>
					);
				})}
			</div>
			{!hideJumpButtons && (
				<div className="flex flex-col">
					{sections.map((section, idx) => (
						<button key={`section_${idx}_button`} className="pl-2 py-px" onClick={() => _handleClick(idx)}>
							<div className="text-center text-warning-500">{section.title}</div>
						</button>
					))}
				</div>
			)}
		</div>
	);
};
