import { Icon } from 'components/Icon';
import { EIcon } from 'configs/constants';
import React from 'react';

interface ISearchbar {
	placeholder?: string;
	value?: string;
	onChange: (value: string) => void;
}

export const Searchbar = (props: ISearchbar) => {
	const { placeholder, value, onChange } = props;

	return (
		<div className="flex items-center mb-4 border border-warning-500 bg-box px-4 py-2">
			<Icon name={EIcon.Search} className="text-black" />
			<input
				className="flex-1 ml-2 text-black"
				style={{ backgroundColor: '#ffffff00' }}
				value={value}
				placeholder={placeholder}
				onChange={(e) => onChange(e.currentTarget.value)}
			/>
		</div>
	);
};
