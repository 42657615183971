import React, { useState } from 'react';

import { Card } from 'components/Container';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { Button } from 'components/Button';
import { isEmptyString } from 'helper';

import ticketcode from '../../assets/ticketcode.jpg';

interface IMissingToken {}

export const MissingToken = (props: IMissingToken) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [ticketCode, setTicketCode] = useState<string>('');
	const [error, setError] = useState<string>('');

	const checkTicketcode = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.checkTicketcode);

	const _checkTicketcode = async () => {
		setIsLoading(true);
		const res = await checkTicketcode({ ticketCode });
		setError(res?.error ?? res ?? '');
		setIsLoading(false);
	};
	return (
		<Card hatColor="text-danger-500">
			<div className="text-warning-500 font-bold text-lg md:text-2xl text-center mb-6">Please enter your ticket code</div>
			<div className="w-3/4 md:w-1/2 flex flex-col self-center items-center mb-6">
				<div className="w-full flex items-center mb-4 border border-warning-500">
					<input
						className="flex-1 px-4 py-2 text-black focus:outline-none"
						value={ticketCode}
						placeholder={'Ticketcode...'}
						disabled={isLoading}
						onChange={(e) => setTicketCode(e.currentTarget.value.trim())}
					/>
				</div>
				{isEmptyString(error) ? null : <p className="text-danger-500">{error}</p>}
				<Button
					isDisabled={isEmptyString(ticketCode)}
					title="Check Ticketcode"
					isLoading={isLoading}
					onClick={_checkTicketcode}
					type="warning"
				/>
			</div>

			<img src={ticketcode} className="w-1/2 md:w-1/4 self-center object-contain my-8" alt="ticketcode" />

			<div className="flex flex-1 flex-wrap self-center text-sm md:text-md text-warning-500">
				<div className="text-center">Having problems? Feel free to contact us</div>
				<div className="cursor-pointer underline ml-1 " onClick={() => window.open('mailto:hello@bitsandpretzels.com')}>
					here
				</div>
			</div>
		</Card>
	);
};
