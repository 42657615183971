import React, { useEffect, useState, ReactNode } from 'react';

import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Spinner } from 'components/Spinner';
import Div100vh from 'react-div-100vh';
import moment, { Moment } from 'moment-timezone';

import { Footer, Navbar } from 'components/Navbar';
import { BookingClosed, BookingNotOpenedYet, MissingToken } from 'components/Info';
import { useDate } from 'components/hooks';
import { TableCaptainBookingList } from 'components/Booking';

interface IAuthGate {
	children: ReactNode;
}

export const AuthGate = (props: IAuthGate) => {
	const { children } = props;
	const { getUserTime, getEventDateFromFormat } = useDate();

	const parsed = queryString.parse(window.location.search);

	const [tokenError, setTokenError] = useState<string | undefined>(undefined);
	const [isTokenResolveLoading, setIsTokenResolveLoading] = useState<boolean>(true);
	const [now, setNow] = useState<Moment>(moment());

	const token = useSelector((store: IRootState) => store.auth.token);
	const decryptedToken = useSelector((store: IRootState) => store.auth.decryptedToken);
	const config = useSelector((store: IRootState) => store.data.config);

	const resolveToken = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.resolveToken);

	const startConfigSync = useRematchDispatch((dispatch: Dispatch) => dispatch.data.startConfigSync);
	const stopConfigSync = useRematchDispatch((dispatch: Dispatch) => dispatch.data.stopConfigSync);

	const startTeamsSync = useRematchDispatch((dispatch: Dispatch) => dispatch.data.startTeamsSync);
	const stopTeamsSync = useRematchDispatch((dispatch: Dispatch) => dispatch.data.stopTeamsSync);
	const startSpeakersSync = useRematchDispatch((dispatch: Dispatch) => dispatch.data.startSpeakersSync);
	const stopSpeakersSync = useRematchDispatch((dispatch: Dispatch) => dispatch.data.stopSpeakersSync);

	const startMyBookingsSync = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.startMyBookingsSync);
	const stopMyBookingsSync = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.stopMyBookingsSync);

	const startTeamBookingsSync = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.startTeamBookingsSync);
	const stopTeamBookingsSync = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.stopTeamBookingsSync);

	useEffect(() => {
		if (parsed.token && parsed.token !== token) {
			_resolveToken();
		} else {
			setIsTokenResolveLoading(false);
			_startSyncs();
		}

		let interval: NodeJS.Timeout;

		interval = setInterval(() => {
			setNow(getUserTime());
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (decryptedToken) {
			_startSyncs();
		} else {
			_stopSyncs();
		}
	}, [decryptedToken]);

	const _startSyncs = () => {
		startConfigSync({});
		startTeamsSync({});
		startSpeakersSync({});
		startTeamBookingsSync({});
		if (decryptedToken?.ticketcode) {
			startMyBookingsSync({});
		}
	};

	const _stopSyncs = () => {
		stopConfigSync({});
		stopTeamsSync({});
		stopSpeakersSync({});
		stopMyBookingsSync({});
		stopTeamBookingsSync({});
	};

	const _resolveToken = async () => {
		const res = await resolveToken({ token: `${parsed.token}` });
		setIsTokenResolveLoading(false);
		if (res) {
			// setTokenError(res);
		}
	};

	const _renderContent = () => {
		if (isTokenResolveLoading) {
			return (
				<div className="w-full flex my-auto justify-center items-center">
					<Spinner />
				</div>
			);
		}

		if (tokenError) {
			return <div>{tokenError}</div>;
		}

		if (!token) {
			return <MissingToken />;
		}

		if (!config) {
			return (
				<div className="h-full flex flex-col justify-center items-center">
					<Spinner />
				</div>
			);
		}

		if (!config?.bookingEnabled && !decryptedToken?.isAdmin) {
			return <BookingNotOpenedYet now={now} start={getEventDateFromFormat(config?.bookingStart)} />;
		}

		if (config?.bookingEnd && getEventDateFromFormat(config?.bookingEnd).isBefore(now)) {
			return <BookingClosed />;
		}

		if (decryptedToken?.id) {
			return <TableCaptainBookingList id={decryptedToken.id} />;
		}

		return children;
	};

	return (
		<Div100vh>
			<div className="flex flex-col w-full h-full ">
				<Navbar />
				<div className="flex flex-1 bg-black overflow-y-auto">
					<div className="flex flex-col w-full flex-1 mx-auto" style={{ maxWidth: 1440 }}>
						{_renderContent()}
					</div>
				</div>
				<Footer />
			</div>
		</Div100vh>
	);
};
