import React from 'react';
import { Button } from 'components/Button';
import { IKVP } from 'configs/interfaces';

interface IFilterCloud {
	value: string[];
	onChange: (val: string[]) => void;
	options: IKVP[];
}

export const FilterCloud = (props: IFilterCloud) => {
	const { value, onChange, options } = props;

	const _handleClick = (key: string) => {
		let _updatedValues = [...value];

		if (key === 'All') {
			_updatedValues = ['All'];
		} else {
			if (_updatedValues.includes('All') && key !== 'All') {
				_updatedValues = [];
			}
			if (_updatedValues.includes(key)) {
				_updatedValues = _updatedValues.filter((e) => e !== key);
			} else {
				_updatedValues.push(key);
			}

			if (_updatedValues.length === 0) {
				_updatedValues.push('All');
			}
		}

		onChange(_updatedValues);
	};

	const _renderButton = (key: string, label: string) => {
		const isActive = value.includes(key);

		return (
			<Button
				key={`filtercloud_option_${key}`}
				type="warning"
				buttonCss={`mb-2 xl:mb-4 mr-2 xl:mr-4`}
				size="sm"
				title={label}
				onClick={() => _handleClick(key)}
				isOutline={!isActive}
			/>
		);
	};

	if (options.length > 0) {
		return (
			<div className="flex flex-col">
				<div className="flex flex-wrap">
					<div className="mb-2 xl:mb-4 mr-2 xl:mr-4" style={{ width: 60 }}>
						<Button
							type="warning"
							buttonCss="w-full"
							title="All"
							size="sm"
							onClick={() => _handleClick('All')}
							isOutline={!value.includes('All')}
						/>
					</div>
					{options.map((option) => _renderButton(option.key, option.label))}
				</div>
			</div>
		);
	}

	return null;
};
