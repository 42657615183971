import React, { ReactNode } from 'react';
import { Icon } from 'components/Icon';
import { EIcon } from 'configs/constants';

import headerImage from '../../assets/header.png';
import { getColorFromCSSString } from 'helper';

import headerOverlay from 'configs/active/headerOverlay.svg';
interface ICard {
	children?: ReactNode | ReactNode[];
	hatColor?: string;
}

export const Card = (props: ICard) => {
	const { children, hatColor } = props;

	const _renderHat = () => {
		return (
			<div className="absolute w-full justify-center -mt-10 md:-mt-14 xl:-mt-24 flex">
				<div
					className="w-20 h-20 md:w-28 md:h-28 xl:w-48 xl:h-48 flex justify-center align-center rounded-full mx-auto"
					style={{ borderColor: getColorFromCSSString(hatColor) }}
				>
					<img src={headerOverlay} alt="overlay" />
				</div>
			</div>
		);
	};

	return (
		<div className={`flex flex-1 pt-20 md:pt-40 xl:pt-80 px-2 pb-4`}>
			<div className="flex flex-col flex-1 w-full bg-black px-1 md:px-6 lg:px-12 ">
				<div className={`relative -mt-16 md:-mt-32 xl:-mt-64`}>
					<div className="px-4">
						<img src={headerImage} className="w-full object-contain" alt="" />
					</div>
					{_renderHat()}
					<div className="flex flex-col flex-1 pt-12 md:pt-16 xl:pt-32">{children}</div>
				</div>
			</div>
		</div>
	);
};
