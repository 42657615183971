import { EVENTTIMEZONE } from 'configs/active/constants';
import { IDateDifference } from 'configs/interfaces';
import moment, { Moment } from 'moment-timezone';
import { useState } from 'react';

export const useDate = () => {
	const [userTimezone] = useState<string>(moment.tz.guess());

	const getUserTime = () => {
		return moment().tz(EVENTTIMEZONE);
	};

	const getDateDifference = (now: Moment, date: Moment) => {
		const diff = Math.abs(now.diff(date, 'seconds'));

		const obj: IDateDifference = {
			days: Math.floor(diff / (60 * 60 * 24)),
			hours: Math.floor((diff / (60 * 60)) % 24),
			minutes: Math.floor((diff / 60) % 60),
			seconds: Math.floor(diff) % 60,
		};

		return obj;
	};

	const getEventDateFromFormat = (date: string) => {
		return moment(date, 'DD.MM.YYYY HH:mm').tz(EVENTTIMEZONE);
	};

	const getEventDateFromISOString = (date: string) => {
		return moment(date).tz(EVENTTIMEZONE);
	};

	return {
		userTimezone,
		getUserTime,
		getDateDifference,
		getEventDateFromFormat,
		getEventDateFromISOString,
	};
};
