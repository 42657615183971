import { ITeamBooking } from 'configs/interfaces';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { TableCaptainBooking } from './TableCaptainBooking';

interface ITableCaptainBookingList {
	id: number;
}

export const TableCaptainBookingList = (props: ITableCaptainBookingList) => {
	const { id } = props;

	const [tableCaptainBookings, setTableCaptainBookings] = useState<ITeamBooking[]>([]);

	const teamBookings = useSelector((store: IRootState) => store.booking.teamBookings);

	useEffect(() => {
		let bookings: typeof tableCaptainBookings = [];

		if (id && teamBookings) {
			bookings = teamBookings.filter((e) => e.teamId === id);
		}

		bookings.sort((a, b) => (a.slotId < b.slotId ? -1 : 1));

		setTableCaptainBookings(bookings);
	}, [teamBookings, id]);

	if (tableCaptainBookings.length > 0) {
		return (
			<div className="w-full xl:w-1/2 flex flex-col self-center">
				{tableCaptainBookings.map((booking) => (
					<TableCaptainBooking key={`tablecaptainbooking_${booking.teamId}`} item={booking} />
				))}
			</div>
		);
	}

	return <div className="flex-1 flex items-center justify-center font-bold text-primary-500">No bookings found yet</div>;
};
