import { createModel } from '@rematch/core';
import { CHECKTICKETCODEURL, RESOLVETOKENURL } from 'configs/active/constants';
import { IResolvedUserToken, IResolveTokenParams } from 'rematch/interfaces';
import { APPLICATIONURL } from 'configs/active/constants';

import type { RootModel } from '.';

type IDefaultState = {
	token?: string;
	decryptedToken?: IResolvedUserToken;
};

export const auth = createModel<RootModel>()({
	state: {
		token: undefined,
		decryptedToken: undefined,
	} as IDefaultState,
	reducers: {
		setToken(state, token: IDefaultState['token']) {
			return {
				...state,
				token,
			};
		},
		setDecryptedToken(state, decryptedToken: IDefaultState['decryptedToken']) {
			return {
				...state,
				decryptedToken,
			};
		},
		clear(state) {
			return {
				...state,
				token: undefined,
				decryptedToken: undefined,
			};
		},
	},
	effects: (dispatch) => ({
		async resolveToken(payload: IResolveTokenParams, store) {
			try {
				const { token } = payload;

				const res: any = await dispatch.request.anonymousRequest({
					url: `${RESOLVETOKENURL}?token=${encodeURIComponent(token)}`,
					method: 'GET',
				});

				if (res?.decryptedToken) {
					dispatch.booking.clear();
					dispatch.booking.stopMyBookingsSync({});
					dispatch.auth.setToken(token);
					dispatch.auth.setDecryptedToken(res.decryptedToken);
				} else {
					return res;
				}
			} catch (error) {
				console.log('resolveToken', error);
			}
		},
		async checkTicketcode(payload) {
			try {
				const { ticketCode } = payload;

				const res: any = await dispatch.request.anonymousRequest({
					url: `${CHECKTICKETCODEURL}?ticketcode=${ticketCode}`,
					method: 'GET',
				});

				if (res?.decryptedToken) {
					dispatch.booking.clear();
					dispatch.booking.stopMyBookingsSync({});
					dispatch.auth.setToken(res.token);
					dispatch.auth.setDecryptedToken(res.decryptedToken);
					return undefined;
				}

				return res.error;
			} catch (error) {
				return error;
			}
		},
		async logout(payload, store) {
			try {
				dispatch.auth.clear();
				dispatch.booking.clear();
				window.open(APPLICATIONURL, '_self');
			} catch (error) {
				console.log('logout', error);
			}
		},
	}),
});
