// =============================================================
// React, React Native, Expo, etc.
// =============================================================
import React from 'react';

// eslint-disable-next-line

// =============================================================
// Function
// =============================================================

import AntDesign from './glyphmaps/AntDesign.json';
import Entypo from './glyphmaps/Entypo.json';
import Feather from './glyphmaps/Feather.json';
import FontAwesome from './glyphmaps/FontAwesome.json';
import Ionicons from './glyphmaps/Ionicons.json';
import MaterialCommunityIcons from './glyphmaps/MaterialCommunityIcons.json';
import MaterialIcons from './glyphmaps/MaterialIcons.json';
import SimpleLineIcons from './glyphmaps/SimpleLineIcons.json';
import Customer from '../../configs/active/Customer.json';

// eslint-disable-next-line
const a = require('./RNVIcon.css');

export const IconFamilies = {
	SimpleLineIcons,
	Feather,
	FontAwesome,
	AntDesign,
	Entypo,
	Ionicons,
	MaterialCommunityIcons,
	MaterialIcons,
	Customer,
};

interface IconProps {
	name?: string; // family:name or name ==> default family = "Feater"
	className?: string;
	tooltip?: string;
	style?: any;
}

export const Icon = (props: IconProps) => {
	const { name, className, style } = props;
	if (!name) {
		return null;
	}

	const _n = name.split(':');
	let _family = 'FontAwesome';
	let _name = '';
	if (_n.length === 2) {
		_family = _n[0];
		_name = _n[1];
	} else {
		_name = _n[0];
	}

	try {
		let code = 0;

		if (_family === 'Customer') {
			const found = IconFamilies[_family].glyphs.find((e) => e.css === _name);
			if (found) {
				code = found.code;
			}
		} else {
			code = IconFamilies[_family][_name];
		}

		return (
			<span
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					fontFamily: _family,
					...style,
					// fontSize: size ?? '20px',
				}}
				className={className || ''}
			>
				{String.fromCharCode(code)}
			</span>
		);
	} catch (error) {
		console.log('error', error);
		return null;
	}
};
