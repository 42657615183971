import { Avatar } from 'components/Avatar';
import { Button } from 'components/Button';
import { CATEGORY3FILTERS, numberOfSlots, slotTimes } from 'configs/active/constants';
import { ITeamBooking } from 'configs/interfaces';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import useReactRouter from 'use-react-router';
import { Card } from 'components/Container';
import { isEmptyString } from 'helper';
import Table from '../assets/table.png';
import moment from 'moment';
import { RichtextRenderer } from 'components/Richtext';
import { EIcon } from 'configs/constants';
import { Icon } from 'components/Icon';
import { Redirect } from 'react-router-dom';
import { ISpeaker, ITeam } from 'configs/interfaces/data23';

export const DetailsScreen = () => {
	const { history, location } = useReactRouter();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [team, setTeam] = useState<ITeam | undefined>(undefined);
	const [schedule, setSchedule] = useState<any>(undefined);
	const [speaker, setSpeaker] = useState<ISpeaker | undefined>(undefined);
	const [teamBooking, setTeamBooking] = useState<ITeamBooking | undefined>(undefined);
	const [isMoreDetailsCollapsed, setIsMoreDetailsCollapsed] = useState<boolean>(false);
	const [remainingSeats, setRemainingSeats] = useState<number>(8);

	const teams = useSelector((store: IRootState) => store.data.teams);
	const speakers = useSelector((store: IRootState) => store.data.speakers);
	const myBookings = useSelector((store: IRootState) => store.booking.myBookings);
	const teamBookings = useSelector((store: IRootState) => store.booking.teamBookings);

	const bookSlot = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.bookSlot);
	const cancelSlot = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.cancelSlot);

	useEffect(() => {
		let _schedule: typeof schedule = undefined;
		let _team: typeof team = undefined;
		let _speaker: typeof speaker = undefined;

		if (location.state) {
			const { slotId } = location.state as any;
			_schedule = slotTimes[slotId - 1];
		}

		if (teams && location.state) {
			const { teamId } = location.state as any;

			_team = teams.find((e) => e.id === teamId);
			if (_team) {
				if (_team.speaker) {
					_speaker = speakers?.find((e) => !e.isDeleted && e.id === _team?.speaker);
				}
			}
		}

		setSchedule(_schedule);
		setTeam(_team);
		setSpeaker(_speaker);
	}, [teams, location.state, speakers]);

	useEffect(() => {
		let _teamBooking: typeof teamBooking = undefined;

		if (team && location.state) {
			const { slotId } = location.state as any;

			_teamBooking = teamBookings.find((e) => e.slotId === slotId && e.teamId === team.id);
			if (team.maxUsers) {
				let _remaining = team.maxUsers;

				const teamBooking = teamBookings.find((e) => e.slotId === slotId && e.teamId === team.id);
				if (teamBooking) {
					_remaining = Math.max(0, _remaining - teamBooking.users.length);
				}
				setRemainingSeats(_remaining);
			}
		}

		setTeamBooking(_teamBooking);
	}, [team, teamBookings]);

	const _bookSlot = async () => {
		const { slotId, teamId } = location.state as any;

		setIsLoading(true);
		await bookSlot({ slotId, teamId });
		setIsLoading(false);
	};

	const _cancelSlot = async () => {
		const { slotId } = location.state as any;

		setIsLoading(true);
		await cancelSlot({ slotId });
		setIsLoading(false);
	};

	const _iHaveBookedThisTeam = () => {
		const { slotId, teamId } = location.state as any;

		return myBookings?.slots.find((e) => e.slotId === slotId && e.teamId === teamId) !== undefined;
	};

	const _renderJobtitleAndCompany = () => {
		let str = '';

		if (speaker?.jobTitle && speaker?.company) {
			str = `${speaker.jobTitle} | ${speaker.company}`;
		} else if (speaker?.jobTitle) {
			str = speaker.jobTitle;
		} else if (speaker?.company) {
			str = speaker.company;
		}

		return <div>{str}</div>;
	};

	const _renderUser = (idx: number) => {
		let fullName;
		if (teamBooking) {
			if (teamBooking.users[idx]) {
				fullName = `${teamBooking.users[idx].firstName}`;
			}
		}

		if (team?.maxUsers && idx + 1 <= team?.maxUsers) {
			return (
				<>
					<Avatar
						fullName={fullName}
						showNameOnHover
						user={teamBooking?.users[idx]}
						size="xs"
						containerCss="flex md:hidden"
						inverse
					/>
					<Avatar
						fullName={fullName}
						showNameOnHover
						user={teamBooking?.users[idx]}
						size="sm"
						containerCss="hidden md:flex xl:hidden"
						inverse
					/>
					<Avatar
						fullName={fullName}
						showNameOnHover
						user={teamBooking?.users[idx]}
						size="md"
						containerCss="hidden xl:flex"
						inverse
					/>
				</>
			);
		}

		return null;
	};

	const _renderTableCaptain = () => {
		let fullName;

		const splitted = team?.teamCategory2 ? team.teamCategory2.split(',') : [];

		if (splitted.includes('topictable')) {
			if (team?.maxUsers && 8 <= team?.maxUsers) {
				if (teamBooking) {
					if (teamBooking.users[7]) {
						fullName = `${teamBooking.users[7].firstName}`;
					}
				}

				return (
					<>
						<Avatar
							fullName={fullName}
							showNameOnHover
							user={teamBooking?.users[7]}
							size="xs"
							containerCss="flex md:hidden"
							inverse
						/>
						<Avatar
							fullName={fullName}
							showNameOnHover
							user={teamBooking?.users[7]}
							size="sm"
							containerCss="hidden md:flex xl:hidden"
							inverse
						/>
						<Avatar fullName={fullName} showNameOnHover user={teamBooking?.users[7]} size="md" containerCss="hidden xl:flex" />
					</>
				);
			}
		}

		fullName = team?.title;

		if (speaker) {
			if (speaker.title) {
				fullName = `${speaker.title} ${speaker.firstName} ${speaker.lastName}`;
			} else {
				fullName = `${speaker.firstName} ${speaker.lastName}`;
			}
		}

		return (
			<>
				<Avatar
					fullName={fullName}
					avatar={speaker?.image ?? team?.image}
					showNameOnHover
					size="xs"
					containerCss="flex md:hidden"
					inverse
				/>
				<Avatar
					fullName={fullName}
					avatar={speaker?.image ?? team?.image}
					showNameOnHover
					size="sm"
					containerCss="hidden md:flex xl:hidden"
					inverse
				/>
				<Avatar
					fullName={fullName}
					avatar={speaker?.image ?? team?.image}
					showNameOnHover
					size="md"
					containerCss="hidden xl:flex"
					inverse
				/>
			</>
		);
	};

	if (location.state) {
		const { slotId, teamId } = location.state as any;

		let fullName = team?.title;

		if (speaker) {
			if (speaker.title) {
				fullName = `${speaker.title} ${speaker.firstName} ${speaker.lastName}`;
			} else {
				fullName = `${speaker.firstName} ${speaker.lastName}`;
			}
		}

		if (slotId && teamId) {
			return (
				<Card hatColor="text-success-500">
					<div className="flex-1 flex flex-col md:flex-row">
						<div className="flex flex-col w-full md:w-1/2 px-4 md:px-12">
							<div className="flex flex-col w-full items-center mx-auto">
								<Avatar
									containerCss="my-auto"
									size="xxl"
									avatar={speaker?.image ?? team?.image}
									fullName={fullName}
									inverse
								/>
								<div className="flex items-center mt-2">
									<div className="font-bold text-warning-500">{fullName}</div>
									{speaker?.linkedInProfileUrl && (
										<button onClick={() => window.open(speaker.linkedInProfileUrl, '_blank')} className="ml-2">
											<Icon name={EIcon.LinkedIn} className="text-xl text-warning-500" />
										</button>
									)}
								</div>
								{_renderJobtitleAndCompany()}
								<div className="flex w-full justify-between mt-2">
									<div className="flex flex-col">
										<div className="text-warning-500 font-bold border-b border-lightgray">CLUSTER</div>
										{team?.teamCategory3?.map((e) => {
											const option = CATEGORY3FILTERS.find((e2) => e2.key === e);
											if (option) {
												return (
													<div key={`${team.id}_tag_${e}`} className="bg-box px-2 py-1 mt-2 text-sm text-center">
														{option.label}
													</div>
												);
											}
											return null;
										})}
									</div>
									<div>
										<div className="flex flex-col">
											<div className="text-warning-500 font-bold border-b border-lightgray">
												{moment(schedule?.startDate, 'DD.MM.YYYY HH:mm').format('DD MMMM')}
											</div>
											<div className="px-2 py-1 mt-2 bg-warning-500 text-black text-sm">{`${moment(
												schedule?.startDate,
												'DD.MM.YYYY HH:mm',
											).format('HH:mm')} - ${moment(schedule?.endDate, 'DD.MM.YYYY HH:mm').format('HH:mm')}`}</div>
										</div>

										<div className="flex flex-col text-center">
											<div className="text-warning-500 font-bold border-b border-lightgray">ROW</div>
											<div className="bg-warning-500 text-black px-2 py-1 mt-2 text-center">{team?.row ?? 'TBD'}</div>
										</div>
										<div className="flex flex-col text-center">
											<div className="text-warning-500 font-bold border-b border-lightgray">TABLE</div>
											<div className="bg-warning-500 text-black px-2 py-1 mt-2 text-center">
												{team?.table ?? 'TBD'}
											</div>
										</div>
									</div>
								</div>

								{!isEmptyString(speaker?.bio) && (
									<div className="flex flex-col w-full mt-4">
										<div className="w-full flex items-center justify-between">
											<div className="text-warning-500 font-bold border-b border-lightgray">MORE DETAILS</div>
											<button onClick={() => setIsMoreDetailsCollapsed(!isMoreDetailsCollapsed)}>
												<Icon
													name={isMoreDetailsCollapsed ? EIcon.ArrowDown : EIcon.ArrowUp}
													className="text-warning-500 text-xl"
												/>
											</button>
										</div>
										{!isMoreDetailsCollapsed && <RichtextRenderer richtext={speaker?.bio} />}
									</div>
								)}

								<div className="flex items-center justify-between mt-12">
									{!_iHaveBookedThisTeam() && (
										<Button
											title="< ALL TABLES"
											type="warning"
											buttonCss="mb-6 mr-6"
											onClick={() =>
												history.push({
													pathname: 'overview',
													state: {
														slotId,
													},
												})
											}
											isDisabled={isLoading}
										/>
									)}
									<Button
										title={numberOfSlots > 1 ? '< MY BOOKINGS' : '< MY BOOKING'}
										type="warning"
										buttonCss="mb-6"
										onClick={() => history.push('/')}
										isDisabled={isLoading}
									/>
								</div>
							</div>
						</div>
						<div className="flex flex-col w-full md:w-1/2 md:px-4 xl:px-8">
							<div
								className={`${
									remainingSeats > 0 ? 'text-warning-500' : 'text-lightgray'
								} font-bold border-b border-lightgray`}
							>{`${remainingSeats} places remaining`}</div>
							<div className="flex items-center">
								<div className="h-full flex flex-col justify-center xl:justify-between px-2 xl:px-4 xl:py-48">
									<div className="mb-4">{_renderTableCaptain()}</div>
									{_renderUser(6)}
								</div>
								<div className="flex-1">
									<div className=" w-full flex items-center justify-between py-2 xl:py-6 px-7 md:px-12 xl:px-20">
										{_renderUser(0)}
										{_renderUser(1)}
									</div>
									<div>
										<img src={Table} className="w-full object-contain" alt="table" />
									</div>
									<div className=" w-full flex items-center justify-between py-2 xl:py-6 px-7 md:px-12 xl:px-20">
										{_renderUser(5)}
										{_renderUser(4)}
									</div>
									<div className="text-center text-sm mt-2 text-warning-500">{`${teamBooking?.users.length ?? 0}/${
										team?.maxUsers
									} places taken`}</div>
								</div>
								<div className="h-full flex flex-col justify-center xl:justify-between px-2 xl:py-48">
									<div className="mb-4">{_renderUser(2)}</div>
									{_renderUser(3)}
								</div>
							</div>
						</div>
					</div>
					<div className="w-full md:w-1/2 mx-auto flex">
						<Button
							title={_iHaveBookedThisTeam() ? 'LEAVE TABLE' : remainingSeats === 0 ? 'FULLY BOOKED' : 'JOIN TABLE'}
							type={_iHaveBookedThisTeam() || remainingSeats === 0 ? 'danger' : 'warning'}
							buttonCss="w-full my-6"
							isDisabled={!_iHaveBookedThisTeam() && remainingSeats === 0}
							onClick={() => {
								if (_iHaveBookedThisTeam()) {
									_cancelSlot();
								} else {
									_bookSlot();
								}
							}}
							isLoading={isLoading}
						/>
					</div>
				</Card>
			);
		}
	}

	return <Redirect to="/" />;
};
