import React from 'react';
import Loader from 'react-loader-spinner';

const color = '#4a5568';

const TYPE = 'TailSpin';

enum ESpinnerSize {
	Small = 25,
	Medium = 40,
	Large = 60,
}
interface ISpinner {
	size?: ESpinnerSize;
}

export const Spinner = (props: ISpinner) => {
	const { size } = props;

	return <Loader type={TYPE} color={color} height={size ?? ESpinnerSize.Small} width={size ?? ESpinnerSize.Small} />;
};
