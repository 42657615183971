import { createModel } from '@rematch/core';
import { db } from 'configs/constants';
import { EVENTCODE } from 'configs/active/constants';
import { IConfig } from 'configs/interfaces';
import { onSnapshot, doc, collection, query, Unsubscribe } from 'firebase/firestore';

import type { RootModel } from '.';
import { ISpeaker, ITeam } from 'configs/interfaces/data23';

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

type IDefaultState = {
	teams: ITeam[];
	teamsSync?: Unsubscribe;
	lastTeamsRead: string;
	speakers: ISpeaker[];
	speakersSync?: Unsubscribe;
	lastSpeakersRead: string;
	config?: IConfig;
	configSync?: Unsubscribe;
};

export const data = createModel<RootModel>()({
	state: {
		teams: [],
		teamsSync: undefined,
		lastTeamsRead: ZEROHOUR,
		speakers: [],
		speakersSync: undefined,
		lastSpeakersRead: ZEROHOUR,
		config: undefined,
		configSync: undefined,
	} as IDefaultState,
	reducers: {
		addTeams(state, teams: IDefaultState['teams']) {
			const _teams = state.teams ? [...state.teams] : [];
			teams.forEach((team) => {
				const idx = _teams.findIndex((e) => e.id === team.id);
				if (idx !== -1) {
					if (team.isDeleted) {
						_teams.splice(idx, 1);
					} else {
						_teams[idx] = team;
					}
				} else if (!team.isDeleted) {
					_teams.push(team);
				}
			});

			return {
				...state,
				teams: _teams,
			};
		},
		setTeamsSync(state, teamsSync: IDefaultState['teamsSync']) {
			return {
				...state,
				teamsSync,
			};
		},
		addSpeakers(state, speakers: IDefaultState['speakers']) {
			const _speakers = state.speakers ? [...state.speakers] : [];
			speakers.forEach((speaker) => {
				const idx = _speakers.findIndex((e) => e.id === speaker.id);
				if (idx !== -1) {
					if (speaker.isDeleted) {
						_speakers.splice(idx, 1);
					} else {
						_speakers[idx] = speaker;
					}
				} else if (!speaker.isDeleted) {
					_speakers.push(speaker);
				}
			});

			return {
				...state,
				speakers: _speakers,
			};
		},
		setSpeakersSync(state, speakersSync: IDefaultState['speakersSync']) {
			return {
				...state,
				speakersSync,
			};
		},
		setConfig(state, config: IDefaultState['config']) {
			return {
				...state,
				config,
			};
		},
		setConfigSync(state, configSync: IDefaultState['configSync']) {
			return {
				...state,
				configSync,
			};
		},
	},
	effects: (dispatch) => ({
		startConfigSync(payload, store) {
			try {
				if (!store.data.configSync) {
					const sync = onSnapshot(doc(db, `${EVENTCODE}/config`), (doc) => {
						const data = doc.data() as IDefaultState['config'];

						dispatch.data.setConfig(data);
					});
					dispatch.data.setConfigSync(sync);
				}
			} catch (error) {
				console.log('startConfigSync', error);
			}
		},
		stopConfigSync(payload, store) {
			try {
				if (store.data.configSync) {
					store.data.configSync();
					dispatch.data.setConfigSync(undefined);
				}
			} catch (error) {
				console.log('stopConfigSync', error);
			}
		},
		startTeamsSync(payload, store) {
			try {
				if (!store.data.teamsSync) {
					const q = query(collection(db, `${EVENTCODE}/data/team`));
					const sync = onSnapshot(q, (querySnapshot) => {
						const _teams: ITeam[] = [];
						querySnapshot.forEach((doc) => {
							const data = doc.data() as ITeam;
							_teams.push(data);
						});

						dispatch.data.addTeams(_teams);
					});

					dispatch.data.setTeamsSync(sync);
				}
			} catch (error) {
				console.log('startTeamsSync', error);
			}
		},
		stopTeamsSync(payload, store) {
			try {
				if (store.data.teamsSync) {
					store.data.teamsSync();
					dispatch.data.setTeamsSync(undefined);
				}
			} catch (error) {
				console.log('stopTeamsSync', error);
			}
		},
		startSpeakersSync(payload, store) {
			try {
				if (!store.data.speakersSync) {
					const q = query(collection(db, `${EVENTCODE}/data/speaker`));
					const sync = onSnapshot(q, (querySnapshot) => {
						const _speakers: ISpeaker[] = [];
						querySnapshot.forEach((doc) => {
							const data = doc.data() as ISpeaker;
							_speakers.push(data);
						});

						dispatch.data.addSpeakers(_speakers);
					});

					dispatch.data.setSpeakersSync(sync);
				}
			} catch (error) {
				console.log('startSpeakersSync', error);
			}
		},
		stopSpeakersSync(payload, store) {
			try {
				if (store.data.speakersSync) {
					store.data.speakersSync();
					dispatch.data.setSpeakersSync(undefined);
				}
			} catch (error) {
				console.log('stopSpeakersSync', error);
			}
		},
	}),
});
