import React from 'react';
import { AuthGate } from 'components/Container';
import { Helmet } from 'react-helmet';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { DetailsScreen, OverviewScreen, MyBookingsScreen } from 'screens';

import { EVENTTITLE, FAVICONURL } from 'configs/active/constants';

function App() {
	return (
		<Router>
			<Helmet>
				<title>{EVENTTITLE}</title>
				<link rel="icon" type="image/png" href={FAVICONURL} />
			</Helmet>
			<AuthGate>
				<Switch>
					<Route key="mybookings" path="/" exact strict component={MyBookingsScreen} />
					<Route key="overview" path="/overview" exact strict component={OverviewScreen} />
					<Route key="details" path="/details" exact strict component={DetailsScreen} />
				</Switch>
			</AuthGate>
		</Router>
	);
}

export default App;
