import { auth } from './auth';
import { booking } from './booking';
import { data } from './data';
import { request } from './request';

import { Models } from '@rematch/core';

export interface RootModel extends Models<RootModel> {
	auth: typeof auth;
	data: typeof data;
	booking: typeof booking;
	request: typeof request;
}

export const models: RootModel = { auth, data, booking, request };
