import { ITeamBookingUser } from 'configs/interfaces';
import React, { useState } from 'react';

interface IAvatar {
	avatar?: string;
	fullName?: string;
	size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
	containerCss?: string;
	showNameOnHover?: boolean;
	user?: ITeamBookingUser;
	inverse?: boolean;
}

export const Avatar = (props: IAvatar) => {
	const { avatar, fullName, size, containerCss, showNameOnHover, user, inverse } = props;

	const [isHovered, setIsHovered] = useState<boolean>(false);

	let sizeString = '';
	let textString = '';

	switch (size) {
		case 'xxs':
			sizeString = 'h-8 w-8';
			textString = 'text-xs';
			break;
		case 'xs':
			sizeString = 'h-12 w-12';
			textString = 'text-sm';
			break;
		case 'md':
			sizeString = 'h-20 w-20';
			textString = 'text-lg';
			break;
		case 'lg':
			sizeString = 'h-24 w-24';
			textString = 'text-xl';
			break;
		case 'xl':
			sizeString = 'h-32 w-32';
			textString = 'text-2xl';
			break;
		case 'xxl':
			sizeString = 'h-40 w-40';
			textString = 'text-3xl';
			break;
		case 'sm':
		default:
			sizeString = 'h-16 w-16';
			textString = 'text-md';
			break;
	}

	const _renderJobtitleAndCompany = () => {
		if (user && (user.jobTitle || user.company)) {
			let str = '';

			if (user.jobTitle && user.company) {
				str = `${user.jobTitle} | ${user.company}`;
			} else if (user.jobTitle) {
				str = user.jobTitle;
			} else if (user.company) {
				str = user.company;
			}

			return <div className="text-black whitespace-nowrap">{str}</div>;
		}

		return null;
	};

	const _renderNameOverlay = () => {
		if (isHovered && fullName && showNameOnHover) {
			return (
				<div className="hidden lg:flex flex-col absolute px-4 py-2 bg-warning-500 mt-2 z-10">
					<div className="text-black font-bold whitespace-nowrap">{fullName}</div>
					{_renderJobtitleAndCompany()}
				</div>
			);
		}

		return null;
	};

	if (avatar) {
		return (
			<div
				onMouseEnter={(e) => setIsHovered(true)}
				onMouseLeave={(e) => setIsHovered(false)}
				className={`relativ flex-shrink-0 p-1 ${sizeString} bg-warning-500 rounded-full ${containerCss}`}
			>
				<img className={`inline-block w-full h-full object-cover rounded-full`} src={avatar} alt="" />
				{_renderNameOverlay()}
			</div>
		);
	}

	let initials = '';
	if (fullName) {
		const splittedFirstname = fullName.split(' ');

		initials = `${splittedFirstname[0].charAt(0).toUpperCase()}`;
	}

	return (
		<div
			onMouseEnter={(e) => setIsHovered(true)}
			onMouseLeave={(e) => setIsHovered(false)}
			onClick={() => {
				if (showNameOnHover) {
					setIsHovered(!isHovered);
				}
			}}
			className={`relative flex-shrink-0 ${containerCss}`}
		>
			<div
				className={`${
					inverse ? 'bg-warning-500 text-black' : 'bg-black text-warning-500'
				} flex items-center justify-center rounded-full font-bold ${sizeString} ${textString}`}
			>
				{initials}
			</div>
			{_renderNameOverlay()}
		</div>
	);
};
